<template>
  <div>
    <template v-if="loading || loadingDriversVehicles">
      <v-layout
        column
        justify-space-around
        style="background-color: white; height: 300px"
      >
        <v-progress-circular
          style="margin: 0 auto"
          :size="70"
          indeterminate
          color="primary"
        />
      </v-layout>
    </template>
    <div v-else class="dispatch-sidebar" :title="null">
      <!-- START RESERVATION INFO -->
      <div class="dispatch-sidebar--reservation-info">
        <div class="dispatch-sidebar--assignments" style="display: flex">
          <CRIcon
            view-box="0 0 24 24"
            :width="20"
            :height="20"
            color="red"
            style="flex-shrink: 0"
          >
            alert_outline
          </CRIcon>
          <p
            class="dispatch-sidebar--error-text"
            style="margin: 0; padding-left: 10px"
          >
            Mass assigning will replace all currently assigned drivers/vehicles
          </p>
        </div>
        <!-- START ASSIGNMENTS  -->
        <div v-for="(info, i) in allInfo" :key="`company-assignment-${i}`">
          <div
            class="dispatch-sidebar--assignments"
            :style="`background: white`"
          >
            <h3>{{ info.companyName }}</h3>
            <DispatchReservationSidebarAssignments
              :drivers="info.drivers"
              :vehicles="info.vehicles"
              :available-drivers="info.drivers"
              :available-vehicles="info.vehicles"
              :reservation="info.reservation"
              :model="info.model"
              class="padding-t-2"
            />
          </div>
        </div>
        <!--div class="dispatch-sidebar--assignments">
          <v-checkbox
            :id="`default-assignment`"
            v-model="defaultReservation"
            class="background-white"
            label=" Default Assignment to all associated Reservations"
          />
        </div-->
        <div class="cr-sidebar-dialog--button-spacer"></div>
        <CRButton
          id="dispatch-sidebar-update-reservation-btn"
          class="dispatch-sidebar--action-btn"
          color="primary"
          :disabled="reservationButtonIsDisabled"
          :loading="assignmentsLoading"
          @click="validateReservationUpdates"
        >
          Update Reservation
        </CRButton>
      </div>
    </div>
  </div>
</template>

<script>
import DispatchReservationSidebarAssignments from '@/components/DispatchReservationSidebarAssignments'
import {
  createMassAssignment,
  deleteMassAssignment,
} from '@/services/reservations'
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    DispatchReservationSidebarAssignments,
  },

  props: {
    allInfo: {
      type: Array,
      default: () => [],
    },
    quoteId: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    reservationIds: {},
    loading: false,
    debounce: undefined,
    defaultReservation: false,
  }),
  computed: {
    reservationButtonIsDisabled() {
      if (this.assignmentsLoading) {
        return true
      }
      return false
    },
    computedModel() {
      return (inputModel) => {
        return [
          {
            tripVehicleGroupId: null,
            name: '',
            vehicleAssignments: inputModel,
          },
        ]
      }
    },
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      setShowConfirmationDetail: 'dispatchNew/setShowConfirmationDetail',
    }),
    resetSavedReservation() {
      setTimeout(() => {
        this.showAlert({
          type: 'success',
          message: `Assignments for Quote ${this.quoteId} updated`,
        })
      }, 100)
      this.setShowConfirmationDetail(false)
      EventBus.$emit('saved-reservation')
      EventBus.$emit('global-table-view-refresh')
      EventBus.$emit('refresh-reservation-detail')
      this.closeSidebar()
      return
    },
    closeSidebar() {
      this.$store.dispatch('app/closeSidebarDialog')
    },
    updateModel(payload, i) {
      let { assignment, index } = payload
      // Pending implementation: Phone Numbers
    },
    async validateReservationUpdates() {
      this.loading = true
      const creationPayload = []
      const deletionPayload = []
      const deletionMap = new Map()
      for (let company of this.allInfo) {
        const companyMassAssignment = {
          vehicleAssignments: [],
          reservationIds: [],
          parentReservationIds: [],
        }
        let companyModel = company.model
        for (let tripVehicleGroup of companyModel) {
          for (let assignmentTemplate of tripVehicleGroup.vehicleAssignments) {
            if (
              assignmentTemplate.vehicle &&
              assignmentTemplate.vehicle.vehicleId
            ) {
              let vehicleAssignment = {
                tripVehicleGroupId: tripVehicleGroup.tripVehicleGroupId,
                vehicleTypeId: assignmentTemplate.vehicleTypeId,
                vehicleId: assignmentTemplate.vehicle.vehicleId,
                driverAssignments: [],
              }
              for (const driverAssignment of assignmentTemplate.driverAssignments) {
                if (driverAssignment.driver.userId) {
                  vehicleAssignment.driverAssignments.push({
                    userId: driverAssignment.driver.userId,
                  })
                } else {
                  // throw { message: 'Drivers not assigned to vehicle' }
                }
              }
              companyMassAssignment.vehicleAssignments.push(vehicleAssignment)
            } else {
              // throw { message: 'Vehicles not assigned
            }
          }
        }
        if (companyMassAssignment.vehicleAssignments.length > 0) {
          companyMassAssignment.parentReservationIds =
            company.parentReservationIds
          companyMassAssignment.reservationIds = company.reservationIds
          creationPayload.push(companyMassAssignment)
        }
        for (let ind = 0; ind < company.parentReservationIds.length; ind++) {
          const prid = company.parentReservationIds[ind]
          const rid = company.reservationIds[ind]
          if (!deletionMap.has(prid)) {
            deletionMap.set(prid, new Set())
          }
          deletionMap.get(prid).add(rid)
        }
      }
      for (let [k, v] of deletionMap) {
        deletionPayload.push({
          parentReservationId: k,
          reservationIds: [...v],
        })
      }

      if (creationPayload.length > 0) {
        await deleteMassAssignment(deletionPayload)
        await createMassAssignment(creationPayload)
        this.resetSavedReservation()
      } else {
        this.loading = false
        this.showAlert({
          type: 'error',
          message: `No Assignments to update`,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dispatch-sidebar {
  display: flex;
  flex-direction: column;
  width: 500px;
  background-color: white;

  h4 {
    font-size: 24px;
  }

  p {
    margin: 0;
  }

  &--confirmation-conflict-box {
    h4 {
      font-weight: bold;
      font-size: 19px;
      margin-left: 60px;
    }

    p {
      margin-left: 5px;
    }
  }

  &--confirmation-description-header {
    font-weight: bold;
    margin-left: 5px;
  }

  &--conflict-box {
    h4 {
      font-weight: bold;
      font-size: 19px;
      margin-left: 38px;
    }
  }

  &--conflict-details {
    display: flex;

    .cr-icon {
      min-width: 38px;
      padding-right: 10px;
    }

    p {
      color: $gray;
    }
  }

  &--conflict-description-header {
    font-weight: bold;
  }

  &--conflict-arrow {
    a {
      display: flex;
      align-items: center;

      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &--gray-text {
    color: $dispatch-gray;
  }

  &--error-box {
    margin-bottom: 20px;

    &-content {
      background-color: $error-pale;
      padding: 20px 10px;
      border-radius: 10px;

      p {
        padding: 0 5px 0 15px;
      }
    }
  }

  &--header-small {
    color: $dispatch-gray;
    font-size: 12px;
  }

  &--reservation-info-content {
    flex: 1;
  }

  &--reservation-info-content > div {
    margin: 40px;
  }

  &--conflict-wrapper > div {
    margin: 40px;
  }

  &--customer-info-box {
    background: $dispatch-off-white;
    padding: 15px 20px;
    border-radius: 5px;
    transition: 0.1s opacity ease-in-out;
  }

  &--error-text {
    color: $error;
  }

  &--due-date {
    text-transform: uppercase;
    background: $success;
    color: white;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 3px;
    margin-left: 5px;
  }

  &--due-date-past {
    background: $error;
  }

  &--trip-total {
    padding-bottom: 30px;
    border-bottom: 1px solid $dispatch-gray;
    margin: 40px 40px 0 40px;
  }

  &--button-spacer {
    min-height: 71px;
    width: 100%;
  }

  &--assignments {
    margin: 0 !important;
    padding: 15px 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }

  &--confirmation-content {
    margin: 20px 40px;
  }

  &--confirmation-cancel {
    bottom: 71px;
    border: none !important;
  }
}

.edit-button {
  cursor: pointer;
  font-weight: bold;
  color: $primary;
  font-size: 13px;
  padding: 7px;
}

::v-deep {
  .v-timeline-item {
    padding-top: 7px;
  }

  .v-timeline-item__dot {
    height: 9px;
    width: 9px;
    left: 0px;
    box-shadow: none;
  }

  .v-timeline-item__inner-dot {
    height: 9px;
    width: 9px;
  }

  .v-timeline:before {
    background: transparent;
    border: none;
    border-right: 1px dashed black;
    top: 22px;
    height: calc(100% - 54px);
  }

  .v-timeline--dense:before {
    left: 3px;
  }

  .v-timeline {
    padding-top: 0;
  }

  .v-timeline-item__body {
    max-width: calc(100% - 21px);
  }

  .theme--light.v-select .v-select__selections {
    color: $gray-base !important;
  }

  .theme--dark.v-select .v-select__selections {
    color: $gray-base !important;
  }

  .theme--dark input {
    color: $gray-base !important;
  }
}

.primary-text {
  color: $primary;
}

.sidebar-link {
  color: $gray-dark;
  text-decoration: none;

  &:hover .dispatch-sidebar--customer-info-box {
    opacity: 0.8;
  }
}

.arrow_back {
  color: $black;
  height: 24px;
  margin-right: 8px;
}

.validate-trip-loading {
  color: $gray-medium-light;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  padding-bottom: 8px;

  span {
    margin-right: 10px;
    margin-bottom: -5px;
  }

  .dot-typing {
    position: relative;
    left: -9999px;
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background-color: $gray-medium-light;
    color: $gray-medium-light;
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px 0 0 0 $gray-medium-light;
    animation: dotTyping 1.5s infinite linear;
  }
}

@keyframes dotTyping {
  0% {
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px 0 0 0 $gray-medium-light;
  }
  16.667% {
    box-shadow: 9992px -5px 0 0 $gray-medium-light,
      9999px 0 0 0 $gray-medium-light, 10006px 0 0 0 $gray-medium-light;
  }
  33.333% {
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px 0 0 0 $gray-medium-light;
  }
  50% {
    box-shadow: 9992px 0 0 0 $gray-medium-light,
      9999px -5px 0 0 $gray-medium-light, 10006px 0 0 0 $gray-medium-light;
  }
  66.667% {
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px 0 0 0 $gray-medium-light;
  }
  83.333% {
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px -5px 0 0 $gray-medium-light;
  }
  100% {
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px 0 0 0 $gray-medium-light;
  }
}
</style>
